// ---------------------------------------------
// Vendors
// ---------------------------------------------
// Bootstrap
@import 'bootstrap/bootstrap';

// Fontawesome 4.7
// $fa-font-path: '../fonts';
@import '~font-awesome/scss/font-awesome';

// Themify-Icons
// $ti-font-path: '../fonts';
@import '~themify-icons-scss/scss/themify-icons';

// Datatables
@import '~datatables.net-bs4/css/dataTables.bootstrap4.min.css';

// Toastr
@import '~toastr/toastr.scss';

// Simplebar
@import '~simplebar/dist/simplebar.min.css';

// ---------------------------------------------
// siQtheme
// ---------------------------------------------
@import "variables";
@import "mixins";

// Layout
@import "layout/login";
@import "layout/base";
@import "layout/header";
@import "layout/sidebar";
@import "layout/main";
@import "layout/footer";
@import "layout/sidebar-right";

// Overrides
@import "overrides/bootstrap";
@import "overrides/datatables";
@import "overrides/select2";
@import "overrides/chartist";
@import "overrides/summernote";
@import "overrides/datepicker";
@import "overrides/ckeditor5";

// Components
@import "components/cards";
@import "components/buttons";
@import "components/forms";
@import "components/checkboxes-radios";
@import "components/switches";
@import "components/modals";
@import "components/alerts";
@import "components/timeline";

// Themes
@import "themes/default";
@import "themes/dark";
@import "themes/blue";

// Media
@import "media";
