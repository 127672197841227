.modal-content {
	border-radius: $main-body-radius;

	.modal-header {
		padding: 1.5rem;
	}

	.modal-body {
		padding: 1.5rem;
	}

	.modal-footer {
		padding: 1rem 1.5rem;
	}
}

.siqmodal {
	.modal-header {
		display: table !important;
		width: 100%;
		text-align: center;

		i {
			display: block;
			font-size: 4em;
		}

		button.close {
			margin-top: -5em;
		}
	}
	.modal-body {
		
	}

	.modal-footer {
		display: table;
		width: 100%;
	}
}

@each $name, $color in $theme-colors {
    .modal.modal-#{$name} {
	     .modal-content {
		        color: color-yiq($color) !important;
		        background: $color;

		        .modal-header {
		        	border-bottom-color: darken($color, 4%);

		        	.close {
						color: color-yiq($color);
						text-shadow: none;
					}
		        }

		        .modal-body {
		        	.form-control-plaintext {
		        		color: color-yiq($color);
		        	}
		        }

		        .modal-footer {
		        	border-top-color: darken($color, 4%);
		        }
	    }
    }
}
